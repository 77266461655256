import { render, staticRenderFns } from "./Activate.vue?vue&type=template&id=f1d9b2e2&scoped=true&"
import script from "./Activate.vue?vue&type=script&lang=ts&"
export * from "./Activate.vue?vue&type=script&lang=ts&"
import style0 from "./Activate.vue?vue&type=style&index=0&id=f1d9b2e2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1d9b2e2",
  null
  
)

/* custom blocks */
import block0 from "./Activate.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAppBar,VBtn,VCard,VCardActions,VCardSubtitle,VCardTitle,VCol,VForm,VProgressCircular,VRow,VTextField})
