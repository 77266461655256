






















































































import Vue from 'vue';
import { mapActions, mapState, mapMutations } from 'vuex';

export default Vue.extend({
  name: "accounts-activate-view",
  data () {
    return {
      activateContent: this.$t('activateContent'),
      valid: true,
      pinInput: '',
      buttonText: this.$t('activateContent.cta1'),
      subtitleMessage: `${this.$t('activateContent.subTitle')}`,
      loading: false,
      pinRules: [
        /* istanbul ignore next */
        (v: string) => !!v || `${this.$t('activateContent.validation')}`,
      ],
    };
  },
  async mounted(): Promise<void> {
    if (!this.userToken) {
      window.location.assign('/log-in?origin=activate');
    }
  },
  watch: {
    pinActivated(newVal: boolean): void {
      if (newVal) {
        this.subtitleMessage = this.$t('activateContent.success');
        this.buttonText = this.$t('activateContent.cta2');
      } else {
        this.subtitleMessage = `${this.$t('activateContent.subTitle')}`;
        this.buttonText = this.$t('activateContent.cta1');
      }
    },
    errorMessages(newVal): void {
      if (newVal !== '') {
        this.loading = false;
        this.buttonText = `${this.$t('activateContent.cta1')}`;
      }
    },
  },
  computed: {
    ...mapState({
      error: (state: any) => state.error,
      pinActivated: (state: any) => state.userInfo.pinActivated,
      userToken: (state: any) => state.userInfo.token,
    }),
    errorMessages() {
      if (this.error) {
        return `${this.$t('activateContent.error')}`;
      }
      return '';
    },
    label() {
      return `${this.$t('activateContent.label')}`;
    }
  },
  methods: {
    ...mapActions({
      activatePin: 'activatePin',
    }),
    ...mapMutations(['SET_USER_PIN_STATUS']),
    async handleCtaClick() {
      if (this.pinActivated) {
        this.reset();
        return;
      }
      const pin = this.pinInput;
      if(this.$refs.form.validate()) {
        this.loading = true;
        await this.activatePin(pin);
        this.loading = false;
      }
    },
    async reset () {
      await this.$store.commit('SET_USER_PIN_STATUS', false);
      this.$refs.form.reset()
      this.$store.dispatch('error', null, { root: true });
    },
  },
  metaInfo(): any {
    return {
      title: 'Funimation: Activate PIN',
      meta: [
        {
          vmid: 'og:title',
          name: 'og:title',
          content: 'Activate your device',
        },
        {
          vmid: 'description',
          name: 'description',
          content: 'Activate your device',
        },
        {
          vmid: 'og:description',
          name: 'og:description',
          content: 'Activate your device',
        },
        {
          vmid: 'og:type',
          name: 'og:type',
          content: 'website',
        },
        {
          vmid: 'og:image',
          name: 'og:image',
          content: 'Image url goes here',
        },
      ],
    };
  },
});
